import Vue from "vue";
import VueRouter from "vue-router";
import Bind from "../views/Bind.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Bind",
    component: Bind,
    meta: {
      title: 'BindMobile'
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title // 设置页面标题
  }
  if (to.path != "/") {
    next({ path: "/" });
  } else {
    next();
  }

})

export default router;
