<template>
  <div
    class="login"
    :style="{ backgroundImage: `url(${game_id[info.appid || 3]?.bg})` }"
  >
    <div class="border-bg gift-info">
      <div class="gift-img">
        <img
          v-for="item in game_id[info.appid || 3]?.imgList"
          :key="item.imgUrl"
          :src="item.imgUrl"
          alt=""
        />
      </div>
      <div class="gift-text rule">
        {{ game_id[info.appid || 3]?.imgText }}
      </div>
    </div>
    <div class="border-bg login-form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="" prop="mobile">
          <div class="form-bg">
            <el-input
              v-model.trim="ruleForm.mobile"
              placeholder="phone"
              :readonly="is_bind"
            >
              <template slot="prepend">84 - </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item v-if="!is_bind">
          <el-col :span="16">
            <el-form-item prop="code">
              <div class="form-bg">
                <el-input
                  v-model.trim="ruleForm.code"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  placeholder="Xin nhập OTP"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="7">
            <el-button class="getCode" type="primary" @click="getCode()">
              {{ codeTime > 0 ? codeTime : "Nhận OTP" }}
            </el-button>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="is_bind && is_receive"
            v-loading.fullscreen.lock="fullscreenLoading"
            class="btn"
          >
            {{ bindText }}
          </el-button>
        </el-form-item>
        <div class="rule">
          Xin kiểm tra tin nhắn zalo để nhận mã xác thực; Nếu bạn chưa nhận được
          mã xác thực, hãy gửi lại yêu cầu sau 60s!
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
const shanghaiList = {
  imgList: [
    { imgUrl: require("../assets/img/gift/8/1.png") },
    { imgUrl: require("../assets/img/gift/8/2.png") },
    { imgUrl: require("../assets/img/gift/8/3.png") },
    { imgUrl: require("../assets/img/gift/8/4.png") },
    { imgUrl: require("../assets/img/gift/8/5.png") },
  ],
  imgText:
    "Tiên Ngọc x100, Trứng Dị Thú x100, Tinh Kim x3, Vé Gia Tốc x5, Búa x10",
  bg: require("../assets/img/gift/8/bg.png"),
};
export default {
  name: "login",
  mixins: [],
  props: {},
  data() {
    return {
      codeTime: 0,
      ruleForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "Xin nhập số điện thoại",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "Xin nhập OTP", trigger: "blur" }],
      },
      info: {},
      bindText: "Liên kết và nhận",
      is_bind: false,
      is_receive: false,
      fullscreenLoading: false,
      game_id: {
        3: shanghaiList,
        8: shanghaiList,
        9: shanghaiList,
        14: shanghaiList,
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.info = this.$route.query;
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.fullscreenLoading = true;
      let params = {
        appid: this.info.appid,
        userid: this.info.userid,
        token: this.info.token,
      };
      this.$http.post("/api/getUserBindInfo", params).then((res) => {
        if (res.code === 200) {
          this.ruleForm = res.data;
          let { is_bind, is_receive } = res.data;
          this.is_bind = is_bind ? true : false;
          this.is_receive = is_receive ? true : false;
          if (is_bind && is_receive) {
            this.bindText = "Đã nhận";
          } else if (!is_bind && !is_receive) {
            this.bindText = "Liên kết và nhận";
          } else if (is_bind && !is_receive) {
            this.bindText = "Nhận";
          }
          this.fullscreenLoading = false;
        } else {
          this.$message.error(res.msg);
          this.fullscreenLoading = false;
        }
      });
    },
    getCode() {
      if (this.ruleForm.mobile === "") {
        this.$message.warning("Xin nhập số điện thoại");
        return;
      }
      if (this.codeTime <= 59 && this.codeTime > 0) {
        return;
      }
      let params = {
        appid: this.info.appid,
        userid: this.info.userid,
        token: this.info.token,
        mobile: this.ruleForm.mobile,
      };
      this.$http.post("/api/sendMobileCode", params).then((res) => {
        if (res.code === 200) {
          this.codeTime = 59;
          let codeTimeInt = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (this.codeTime == 0) {
              clearInterval(codeTimeInt);
            }
          }, 1000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    submitForm(formName) {
      var _this = this;
      this.fullscreenLoading = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            appid: this.info.appid,
            userid: this.info.userid,
            token: this.info.token,
            mobile: this.ruleForm.mobile,
            code: this.ruleForm.code,
            server_id: this.info.server_id,
            role_id: this.info.role_id,
          };
          this.$http.post("/api/bindMobileAndGift", params).then((res) => {
            if (res.code === 200) {
              this.getData();
              _this.$message.success(res.msg);
            } else {
              _this.$message.error(res.msg);
            }
            this.fullscreenLoading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-input {
  width: 99%;
}
/deep/ .el-input__inner {
  border: none;
  background-color: transparent;
}
/deep/ .el-form-item:nth-last-child(1) {
  margin-bottom: 0;
}
/deep/ .el-form-item__error {
  left: 20px;
}
/deep/.el-input-group__prepend {
  border: none;
  padding: 0 10px;
}
.el-button {
  margin-top: 20px;
  width: 95%;
  border-radius: 10px;
}
.login {
  background-color: #fff;
  height: 1200px;
  width: 100%;
  overflow: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(../assets/img/bg1.png);
  position: relative;
}

.border-bg {
  width: 85%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #fff;
  padding: 25px;
  border-radius: 30px;
}
.gift-info {
  top: 680px;
}
.gift-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.gift-img img {
  width: 50px;
  height: 50px;
}
.gift-text {
  margin-top: 15px;
}
.login-form {
  top: 860px;
}
.form-bg {
  background-color: #f5f5f5;
  border-radius: 10px; /* 设置圆角，20px 为圆角半径 */
  padding: 5px 0;
}
.getCode {
  width: 100% !important;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.rule {
  text-align: left;
  color: #d4792f;
  font-size: 15px;
}
@media screen and (max-width: 1024px) {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .el-button {
    width: 80%;
  }
  .border-bg {
    padding: 10px;
    border-radius: 15px;
  }
  .gift-img {
    width: 100%;
  }
  .gift-info {
    top: 360px;
  }
  .login-form {
    top: 500px;
  }
  .login {
    height: 750px;
  }
  .btn {
    width: 100%;
    margin-top: 10px;
  }
  .rule {
    text-align: left;
    color: #d4792f;
    font-size: 11px;
  }
}
</style>
